import React, { useCallback, useState } from 'react';
import { Dropdown } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as IconArrowDown } from '../../images/iconArrowDown.svg';
import FormattedMessage from '../../localization/FormatMessage';
import { ExportFormat, exportMeasurements } from '../../api/utilsService';
import styles from './ButtonExport.styl';
import { ProductCode } from '../../types/proceq';
import { getFileNameFromContentDisposition } from '../../utils/fileUtils';
import { classNames } from '../../utils/styleUtils';
import { useProceqAppConfigs } from '../../hooks/useConfig';
import { formatConfig } from './formatOptionConfig';
import { ExportOption, useMeasurementExportContext } from './MeasurementExportProvider';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { ExportAction, MeasurementPage } from '../../analytics/analyticsConstants';
import { logExportUserAction } from '../../api/analyticsEventService';
import { checkLRJConditions } from './MeasurementExportModal';
import InvalidShareExportModal, { InvalidShareExportType } from '../DataView/DataViewModal/InvalidShareExportModal';
import { AntdMenuItem } from '../../types/antd';

interface DownloadProps {
    product: ProductCode;
    mIDs: string[];
    className?: string;
    type?: ButtonType;
    analyticsPage: MeasurementPage;
    setSelectedKeys?: (keys: string[]) => void;
    fileType: string;
}

export const ButtonExportWifi: React.FunctionComponent<DownloadProps> = (props) => {
    const { product, mIDs, className, fileType, type, analyticsPage, setSelectedKeys } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [invalidIDs, setInvalidIDs] = useState<string[]>([]);
    const [exportInfo, setExportInfo] = useState<{ format: string; exportOption: ExportOption } | undefined>(undefined);
    const proceqAppConfigs = useProceqAppConfigs();
    const isLongRunningJobEnabled = proceqAppConfigs.GPR?.IS_LONG_RUNNING_JOB_ENABLE_COMMON === 'YES';

    const { exportOptions, setExportConfig } = useMeasurementExportContext();

    const exportValidMeasurements = useCallback(
        async (measurementIDs: string[], format: string, exportOption: ExportOption) => {
            if (product === ProductCode.PROFOMETER && format === ExportFormat.CSV) {
                setExportConfig({
                    mIDs: measurementIDs,
                    product,
                    fileType,
                    showModal: true,
                    setSelectedKeys,
                    type: exportOption.type,
                });
            } else {
                const exportAsURL = checkLRJConditions(product, format, isLongRunningJobEnabled);
                try {
                    setIsLoading(true);
                    const response = await exportMeasurements({
                        product,
                        mIDs: measurementIDs,
                        format,
                        fileType: 'measurement',
                        exportAsURL,
                        options: exportOption.additionalExportOptions,
                    });
                    logExportUserAction(format, measurementIDs.length);

                    if (response.data instanceof Blob) {
                        saveAs(
                            response.data,
                            getFileNameFromContentDisposition(response.headers['content-disposition'] || '')
                        );
                    } else if (exportAsURL) {
                        window.open(response.data.url, '_blank');
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [fileType, isLongRunningJobEnabled, product, setExportConfig, setSelectedKeys]
    );

    const handleExport = useCallback(
        async (format: string, exportOption: ExportOption) => {
            const { invalidIDs } = exportOption;
            if (invalidIDs.length > 0) {
                setInvalidIDs(invalidIDs);
                setExportInfo({ format, exportOption });
            } else {
                await exportValidMeasurements(mIDs, format, exportOption);
                analytics.logExportMeasurement(analyticsPage, product, format, mIDs.length, ExportAction.export);
            }
        },
        [analyticsPage, exportValidMeasurements, mIDs, product]
    );

    const handleClick = () => {
        setExportConfig({
            product,
            mIDs,
        });
        analytics.logViewExportOptions(analyticsPage, product);
    };

    const menuItems: AntdMenuItem[] = exportOptions.map((item) => ({
        key: item.type,
        onClick: () => handleExport(formatConfig[item.type].format, item),
        label: <FormattedMessage id={formatConfig[item.type].titleID} />,
        disabled: item.active === false,
    }));

    const handleExcludeAndExport = () => {
        const validIDs = mIDs.filter((id) => !invalidIDs.includes(id));
        if (exportInfo) {
            exportValidMeasurements(validIDs, exportInfo.format, exportInfo.exportOption).finally(() => {
                setInvalidIDs([]);
                setExportInfo(undefined);
                if (setSelectedKeys) {
                    setSelectedKeys(validIDs);
                }
            });
            analytics.logExportMeasurement(
                analyticsPage,
                product,
                exportInfo.format,
                mIDs.length,
                ExportAction.excludeAndExport
            );
        }
    };

    return (
        <>
            <Dropdown
                disabled={mIDs.length < 1 || isLoading}
                menu={{ items: menuItems }}
                placement="bottomRight"
                trigger={['click']}
                getPopupContainer={(node) => node.parentElement?.parentElement ?? node}
            >
                <AnalyticsButton
                    type={type || 'primary'}
                    className={classNames(styles.button_export, className)}
                    onClick={handleClick}
                >
                    <FormattedMessage id="App.Export" />
                    {isLoading ? (
                        <LoadingOutlined className={styles.icon} />
                    ) : (
                        <IconArrowDown className={styles.icon} />
                    )}
                </AnalyticsButton>
            </Dropdown>
            <InvalidShareExportModal
                visible={invalidIDs.length > 0}
                onClose={() => {
                    setInvalidIDs([]);
                    if (exportInfo) {
                        analytics.logExportMeasurement(
                            analyticsPage,
                            product,
                            exportInfo.format,
                            mIDs.length,
                            ExportAction.cancel
                        );
                    }
                }}
                invalidType={InvalidShareExportType.export}
                invalidMeasurementIDs={invalidIDs}
                onExclude={handleExcludeAndExport}
                selectedKeysCount={mIDs.length}
            />
        </>
    );
};

export default ButtonExportWifi;
