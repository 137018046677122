import { DateTimeString } from './proceq';

export const SPATIAL_DATA_FILE_TYPE = 'spatialData';

export const SPATIAL_DATA_CATEGORY_ID = 'cadgis';

export interface UserFile {
    id: string;
    fid: string;
    uid: number;
    extension: string;
    createdBy: number;
    updatedBy: number;
    clientCreated: number;
    clientUpdated: number;
    created: DateTimeString;
    updated: DateTimeString;
    name: string;
    status: string;
    artifacts?: { status?: string }[];
}

export interface UserFileExtensions {
    id: string;
    name: string;
    extensions: string[];
}

// default to 5MB
export const DEFAULT_MAX_FILE_SIZE = 50 * 1024 * 1024;

export enum SpatialDataProjectionType {
    utm = 'utm',
    country = 'country',
    relative = 'relative',
}

export enum SpatialDataFormFieldNames {
    projectionType = 'projectionType',
    utmZone = 'utmZone',
    utmZoneEPSG = 'utmZoneEPSG', // this is hidden from user but sent to backend
    country = 'country',
    coordinateSystem = 'coordinateSystem',
    epsg = 'epsg',
}

export const REQUIRED_FIELDS_BY_PROJECT_TYPE: Record<SpatialDataProjectionType, SpatialDataFormFieldNames[]> = {
    [SpatialDataProjectionType.utm]: [
        SpatialDataFormFieldNames.projectionType,
        SpatialDataFormFieldNames.utmZone,
        SpatialDataFormFieldNames.utmZoneEPSG,
    ],
    [SpatialDataProjectionType.country]: [
        SpatialDataFormFieldNames.projectionType,
        SpatialDataFormFieldNames.coordinateSystem,
        SpatialDataFormFieldNames.epsg,
        SpatialDataFormFieldNames.country,
    ],
    [SpatialDataProjectionType.relative]: [SpatialDataFormFieldNames.projectionType, SpatialDataFormFieldNames.epsg],
};

// there's no API to get folder count, use limit 1 to get the totalRecords
export const FOLDER_COUNT_PARAM = { limit: 1 };

export interface UserFileOption {
    countryCoordinateSystems: { country: string; coordinateSystems: { epsg: number; name: string }[] }[];
    utmZones: { epsg: number; name: string }[];
}

export interface SpatialDataOption {
    epsg: number;
    label: string;
    value: string;
}

export interface SpatialDataModalFieldOptions {
    utmZones: SpatialDataOption[];
    countries: { value: string; label: string }[];
    countryCoordinateSystems: { [key: string]: SpatialDataOption[] };
}
