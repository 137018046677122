import { routeUtils } from 'tds-common-fe/src/lib/index';
import dayjs from 'dayjs';
import { get, post, thunkGet } from './apiService';
import { getApiURL } from './ApiURL';
import { ProductCode } from '../types/proceq';
import { handleError } from './error';
import { receivedProceqAppConfigs } from '../actions/config';
import { APIResponse } from '../types/api';

interface GetFileParams {
    product?: ProductCode;
    mID: string;
    aID?: string;
    fID?: string;
}

const fileTimeout = 1000 * 60 * 30; // Timeout 30 mins

export const getFile = async (params: GetFileParams) => {
    type Response = ArrayBuffer;
    const url = getApiURL('GET_FILE');
    const response = await get<Response>(url, {
        injectToken: true,
        params: { ...params },
        config: {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'arraybuffer',
            timeout: fileTimeout,
        },
    });
    return response;
};

interface GetAttachmentParams {
    product?: ProductCode;
    mID: string;
    fID: string;
}

export const getAttachment = async (params: GetAttachmentParams) => {
    type Response = Blob;
    const url = getApiURL('GET_FILE');
    const response = await get<Response>(url, {
        injectToken: true,
        params: { ...params },
        config: {
            headers: { 'Content-Type': 'application/zip' },
            responseType: 'blob',
            timeout: fileTimeout,
        },
    });
    return response;
};

interface GetSharingURLParams {
    mIDs: string[];
    product: string;
    tokenValidityMinutes: number;
}

export const getSharingURL = async (params: GetSharingURLParams) => {
    type Response = {
        size: number;
        key: string;
        domain: string;
        url: string;
    };
    const { product, mIDs, tokenValidityMinutes } = params;
    const url = getApiURL('GET_SHARING_URL');
    const response = await post<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: {
            product,
            mIDs,
            scope: {
                groups: [],
                tokenValidityMinutes,
                type: 2,
                users: [],
            },
        },
        config: {
            timeout: fileTimeout,
        },
    });

    return response;
};

export enum ExportFormat {
    JSON = 'json',
    CSV = 'csv',
    HTML = 'html',
    SEGY = 'segy',
    DXF = 'dxf',
    PDF = 'pdf',
    RAW = 'raw',
    KML = 'kml',
    SHP = 'shp',
    DOCX = 'docx',
}

export enum CSVExportFormat {
    csvSingle = 'csv_single',
    csvSeparated = 'csv_separated',
}

export enum UploadCustomCurveFormat {
    CSV = 'csv',
}

// Format is still `pdf` but fileType need to change to specific one
export enum PDFExtendedFileType {
    Uniformity = 'uniformity',
    China = 'china',
    Europe = 'eu',
}

interface ExportMeasurementsParams {
    product: ProductCode;
    fileType?: string;
    mIDs: string[];
    format: string;
    exportAsURL: boolean;
    language?: string;
    attachmentIDs?: string[];
    k?: string;
    d?: string;
    options?: { [key: string]: any };
}

export const exportMeasurements = async (params: ExportMeasurementsParams) => {
    type Response = Blob | { url: string };
    const {
        product,
        mIDs,
        format,
        fileType = 'measurement',
        exportAsURL,
        language,
        attachmentIDs,
        k,
        d,
        options,
    } = params;
    const url = getApiURL(exportAsURL ? 'EXPORT_REPORT_AS_URL' : 'EXPORT_REPORTS');
    const languageConfig = language ? { headers: { 'X-Accept-Language': language } } : {};

    const response = await post<Response>(
        url,
        {
            errorHandler: async (error) => {
                if (error?.response?.data instanceof Blob) {
                    const data = await error.response.data.text();
                    error.response.data = JSON.parse(data);
                }
                handleError(error);
            },
            injectToken: true,
            params: {
                product,
                format,
                fileType,
                iDs: mIDs,
                attachmentIDs,
                timeZoneOffset: dayjs().utcOffset(),
                k,
                d,
                options,
            },
            config: {
                responseType: exportAsURL ? 'json' : 'blob',
                timeout: fileTimeout,
                ...languageConfig,
            },
        },
        true
    );
    return response;
};

export const getProceqAppConfig = async (product: ProductCode = ProductCode.GPR) => {
    type Response = any;
    const url = getApiURL('GET_PROCEQ_APP_CONFIG');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { product },
    });

    if (response) {
        dispatch(receivedProceqAppConfigs({ product, appConfigs: response }));
    }

    return response;
};

export const getAppleMapToken = async () => {
    type Response = APIResponse<{ token: string }>;
    const url = getApiURL('APPLE_MAP_TOKEN');
    return get<Response>(url, {
        injectToken: true,
    });
};

// token will only be valid for 10 min
export const getAppleMapTokenHTMLExport = async (k: string, d: string) => {
    type Response = APIResponse<{ token: string }>;
    const url = routeUtils.makeQueryPath(getApiURL('APPLE_MAP_TOKEN_PQ'), { k, d });
    return get<Response>(url, {});
};

export const getBackendVersion = async () => {
    type Response = APIResponse<{ sversion: string }>;
    const { response } = await thunkGet<Response>(getApiURL('BACKEND_HEALTH'), { errorHandler: handleError });
    return response;
};
