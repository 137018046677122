import React from 'react';
import StaticMap from '../StaticMap';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { ProductCode, ScanType } from '../../../../../types/proceq';
import { getGSRepetitionRate } from '../../utils/getGSRepetitionRate';

export const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({
    log,
    product,
    productModel,
    isMetric,
    scanType,
    hasMA8000,
}) => {
    const { latitide, longitude, measurementInfo } = log.content;
    const coordinates = { lat: +latitide, lng: +longitude };
    const { fileFolder, mode, resolution, repetitionRate, dielectricConstant, coordinateSystem } = measurementInfo;

    if (isMetric === undefined || scanType === undefined) {
        return null;
    }

    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName: fileFolder.fileName,
                        folderName: fileFolder.folderName,
                    }}
                />
            </b>
            <br />
            <FormattedMessage id="Proceq.LogbookCreationMode" values={{ value: mode }} />
            <br />
            {product === ProductCode.GPR_SOIL && scanType === ScanType.AreaScan && (
                <>
                    <ConvertedUnits
                        id="Proceq.LogbookEntryGridSize"
                        unitId={'GPR_SOIL.CSV.Grid Size - X'}
                        scanType={scanType}
                        isMetric={isMetric}
                        values={[measurementInfo.grid.gridSizeWidth, measurementInfo.grid.gridSizeHeight]}
                    />
                    <br />
                    <ConvertedUnits
                        id="Proceq.LogbookEntryGridSpacing"
                        unitId={'GPR_SOIL.CSV.Grid Spacing'}
                        scanType={scanType}
                        isMetric={isMetric}
                        values={[measurementInfo.grid.gridSpacing]}
                    />
                    <br />
                </>
            )}
            {productModel !== 'GPR_PALM' && (
                <>
                    <FormattedMessage id="Proceq.LogbookEntryResolution" values={{ value: resolution }} />
                    <br />
                </>
            )}
            <ConvertedUnits
                id="Proceq.LogbookEntryRepetitionRate"
                unitId="GPR.Settings Panel.Repetition rate"
                scanType={scanType}
                isMetric={isMetric}
                values={[product === ProductCode.GPR_SOIL ? getGSRepetitionRate(repetitionRate) : repetitionRate]}
            />
            <br />
            <ConvertedUnits
                id="Proceq.LogbookEntryDielectricConstant"
                unitId="GPR.Logbook Panel.Dielectric Constant"
                scanType={scanType}
                isMetric={isMetric}
                values={[dielectricConstant]}
            />
            <br />
            {product === ProductCode.GPR_SOIL && hasMA8000 && (
                <>
                    <FormattedMessage id="Proceq.LogbookEntryCoordinateSystem" values={{ value: coordinateSystem }} />
                    <br />
                </>
            )}
            <StaticMap coordinates={coordinates} />
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
