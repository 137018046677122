import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { getFileName } from 'tds-common-fe/src/lib/utils/fileUtils';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from '../DataView.styl';
import TooltipText from '../../shared/TooltipText';
import EditButton from '../../shared/Buttons/EditButton';
import { UserFile } from '../../../types/userFiles';
import { renderDate, SearchDropdown } from './columnUtils';
import NameColumnTooltip from './NameColumnTooltip';

enum QueryKey {
    Name = 'name',
    Created = 'created',
}

export const getSpatialDataColumns = (onRenameFile: (record: UserFile) => void): ColumnsType<UserFile> => [
    {
        key: QueryKey.Name,
        dataIndex: ['name'],
        title: <FormattedMessage id="Proceq.TableDescription" />,
        width: 200,
        fixed: 'left',
        sorter: true,
        filterDropdown: SearchDropdown,
        render: (value: string, record) => {
            const fileName = getFileName(value);
            return (
                <div className={styles.title_wrapper}>
                    <div>
                        <TooltipText text={fileName} />
                        <EditButton onClick={() => onRenameFile(record)} />
                        {record.artifacts?.some((artifact) => artifact.status === 'pending') && (
                            <NameColumnTooltip title="DataView.Folder.SpatialData.Pending.Tooltip" />
                        )}
                    </div>
                </div>
            );
        },
    },
    {
        dataIndex: QueryKey.Created,
        key: QueryKey.Created,
        title: <FormattedMessage id="App.Uploaded" />,
        width: 180,
        sorter: true,
        render: renderDate,
    },
];
