import React, { useContext, useEffect, useMemo, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../localization/FormatMessage';
import AnalyticsButton from '../AnalyticsComponents/Button';
import { ReactComponent as CloseIcon } from '../../images/iconClose.svg';
import styles from './FreeTrialModal.styl';
import { LocalizationContext } from '../../localization/LocalizedIntlProvider';
import useCurrentUserID from '../../hooks/useCurrentUser';
import { FormatIDs } from '../../types';
import { getLabelFromGlobalDataKey } from '../DataView/RegisteredInfo/helper';
import StyledModal from '../shared/StyledModal';
import { getEagleIDContactSales, getEagleIDFeatureInfo, getEagleIDRequestFreeTrial } from '../Routes/urls';
import { LicenseProduct } from '../../types/license';
import { ProductCode } from '../../types/proceq';
import config from '../../config';
import analytics from '../../analytics/firebaseAnalytics';
import { SubscriptionAction } from '../../analytics/analyticsConstants';
import { iFramePageListener } from '../../utils/iFramePageListener';
import { IFramePopupMessageContext } from '../App/IFramePopupMessageProvider';

interface FreeTrialProps {
    productCode?: ProductCode | LicenseProduct;
    licenseActionState: LicenseActionState;
    onClose: () => void;
    showFreeLicense?: boolean;
    open: boolean;
    // If provided, it will use the upgrade API /license/request/upgrade. Else /license/request/contact
    tier?: string;
}

export enum LicenseActionState {
    activateFreeTrial,
    contactSales,
}

const stateData: {
    [key in LicenseActionState]: {
        title?: React.ReactNode;
        bodyText?: FormatIDs;
        buttonText: FormatIDs;
        buttonLogEvent?: (productCode: string) => void;
        closeLogEvent?: (productCode: string) => void;
    };
} = {
    [LicenseActionState.activateFreeTrial]: {
        buttonText: 'License.Start.Free.Trial',
        buttonLogEvent: (productCode: string) =>
            analytics.logSubscriptionEvent(SubscriptionAction.startFreeTrial, productCode),
        closeLogEvent: (productCode: string) =>
            analytics.logSubscriptionEvent(SubscriptionAction.startFreeTrialClose, productCode),
    },
    [LicenseActionState.contactSales]: {
        title: <FormattedMessage id="Contact.Header.Title" />,
        buttonText: 'License.Contact.Sales',
    },
};

const CONTACT_SALES_TOP_BOTTOM_PADDING = 24;

const FreeTrialModal: React.FunctionComponent<FreeTrialProps> = (props) => {
    const { open, productCode, licenseActionState, onClose, showFreeLicense = true, tier } = props;
    const [formHeight, setFormHeight] = useState(150);
    const [iframeModalLoaded, setIframeModalLoaded] = useState(false);
    const { language } = useContext(LocalizationContext);
    const userID = useCurrentUserID();

    const [iframeURL, setIframeURL] = useState('');
    const [isContactSales, setIsContactSales] = useState(licenseActionState === LicenseActionState.contactSales);
    const showButtons = !isContactSales;
    const iFrameBodyPadding = isContactSales ? undefined : `10px ${CONTACT_SALES_TOP_BOTTOM_PADDING}px`;

    const contactSalesUrl = userID
        ? routeUtils.makeQueryPath(getEagleIDContactSales(userID.toString()), {
              parentOrigin: location.origin,
              origin: 'workspace',
              version: config.APP_VERSION,
              client: 'web',
              theme: 'dark',
              lang: language,
              showTitle: 'false',
              showProduct: 'true',
              product: productCode,
              ...(tier
                  ? {
                        isUpgradeRequest: 'true',
                        showTiers: 'true',
                        tier,
                    }
                  : {}),
          })
        : '';

    const requestFreeTrialUrl = userID
        ? routeUtils.makeQueryPath(getEagleIDRequestFreeTrial(userID.toString()), {
              parentOrigin: location.origin,
              origin: 'workspace',
              version: config.APP_VERSION,
              client: 'web',
              theme: 'dark',
              lang: language,
              product: productCode,
          })
        : '';

    const modalTitle = useMemo(() => {
        if (tier) {
            return <FormattedMessage id="Contact.Header.Title.Upgrade" />;
        }
        if (isContactSales) {
            if (licenseActionState === LicenseActionState.activateFreeTrial) {
                return <FormattedMessage id="License.Start.Free.Trial.Title" />;
            }
            return <FormattedMessage id="Contact.Header.Title" />;
        }
        if (licenseActionState === LicenseActionState.activateFreeTrial) {
            return getLabelFromGlobalDataKey('availproductsfull', productCode);
        }
        return stateData[licenseActionState].title;
    }, [isContactSales, licenseActionState, productCode, tier]);

    useEffect(() => {
        if (userID) {
            if (licenseActionState === LicenseActionState.contactSales) {
                setIframeURL(contactSalesUrl);
            } else {
                setIframeURL(
                    routeUtils.makeQueryPath(getEagleIDFeatureInfo(userID.toString()), {
                        parentOrigin: location.origin,
                        product: productCode,
                        origin: 'workspace',
                        version: config.APP_VERSION,
                        client: 'web',
                        theme: 'dark',
                        lang: language,
                        isFreeTrial: showFreeLicense.toString(),
                    })
                );
            }
        }
    }, [contactSalesUrl, language, licenseActionState, productCode, showFreeLicense, userID]);

    const { setUpListener, removeListener } = useContext(IFramePopupMessageContext);
    useEffect(() => {
        const listener = (event: MessageEvent) => {
            iFramePageListener(event, setFormHeight);
        };
        window.addEventListener('message', listener);
        setUpListener();
        return () => {
            removeListener();
            window.removeEventListener('message', listener);
        };
    }, [removeListener, setUpListener]);

    const handleContactSales = (url: string) => {
        if (userID) {
            setIframeURL(url);
            setIsContactSales(true);
            setIframeModalLoaded(false);
        }
    };

    return (
        <StyledModal
            className={styles.modal_container}
            title={modalTitle}
            getContainer={() => document.querySelector('#root') || document.body}
            open={open}
            onCancel={() => {
                if (stateData[licenseActionState].closeLogEvent) {
                    stateData[licenseActionState].closeLogEvent!(productCode ?? '');
                }
                onClose();
            }}
            footer={
                showButtons ? (
                    <AnalyticsButton
                        type="primary"
                        className={styles.activate_license_button}
                        onClick={() => {
                            if (licenseActionState === LicenseActionState.activateFreeTrial) {
                                handleContactSales(requestFreeTrialUrl);
                            } else {
                                handleContactSales(contactSalesUrl);
                            }
                            if (stateData[licenseActionState].buttonLogEvent) {
                                stateData[licenseActionState].buttonLogEvent!(productCode ?? '');
                            }
                        }}
                    >
                        <FormattedMessage id={stateData[licenseActionState].buttonText} />
                    </AnalyticsButton>
                ) : null
            }
            width={625}
            style={{ '--var--iframe-height': formHeight } as React.CSSProperties}
            closeIcon={<CloseIcon />}
            destroyOnClose
            centered
            styles={{
                body: {
                    height: `calc(var(--var--iframe-height)*1px + ${isContactSales ? CONTACT_SALES_TOP_BOTTOM_PADDING * 2 : 0}px)`,
                    padding: iFrameBodyPadding,
                    maxHeight: isContactSales ? 'calc(100vh - 200px)' : undefined,
                },
            }}
        >
            {stateData[licenseActionState].bodyText && !isContactSales ? (
                <div className={styles.modal_content_wrapper}>
                    <FormattedMessage id={stateData[licenseActionState].bodyText!} />
                </div>
            ) : (
                <iframe
                    className={iframeModalLoaded ? styles.iframe_modal_container_loaded : styles.iframe_modal_container}
                    src={iframeURL}
                    title="gpr_insights_modal"
                    scrolling="no"
                    frameBorder="0"
                    onLoad={() => setIframeModalLoaded(true)}
                />
            )}
        </StyledModal>
    );
};

export default FreeTrialModal;
