import { eventPublisher } from 'tds-common-fe';
import config from '../config';
import { PublisherEvent } from '../types/eventPublisher';

type PopupMessageType = 'warning' | 'success' | 'error';

interface PopupMessageData {
    messageType: PopupMessageType;
    messageContent: string;
}

const types = {
    postPageHeight: '',
    popupMessage: '',
};
type EventType = keyof typeof types;

const isTrustedSource = (event: MessageEvent) =>
    event.origin === config.EAGLE_ID_DOMAIN || (config.APP_ENV === 'dev' && location.hostname === 'localhost');

// page height listener is needed at component level
export const iFramePageListener = (event: MessageEvent, setFormHeight: (height: number) => void) => {
    if (!isTrustedSource) return;
    const { type, data }: { type: EventType; data: any } = event.data;

    if (type === 'postPageHeight') {
        if (typeof setFormHeight === 'function' && !isNaN(data)) {
            setFormHeight(data);
        }
    }
};

// popup message listener is needed at global level, use IFramePopupMessageContext to set up listener
export const iFramePopupMessageListener = (event: MessageEvent) => {
    if (!isTrustedSource) return;
    const { type, data }: { type: EventType; data: any } = event.data;

    if (type === 'popupMessage') {
        const { messageType, messageContent }: PopupMessageData = data;
        if (messageType === 'error' && messageContent) {
            eventPublisher.publish(PublisherEvent.ANTD_ERROR_MESSAGE, messageContent);
        }
    }
};
