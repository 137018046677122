import React, { createContext, useState } from 'react';
import { noop } from 'lodash';
import { iFramePopupMessageListener } from '../../utils/iFramePageListener';

interface IFramePopupMessageContextState {
    setUpListener: () => void;
    removeListener: () => void;
}

const listener = (event: MessageEvent) => {
    iFramePopupMessageListener(event);
};

export const IFramePopupMessageContext = createContext<IFramePopupMessageContextState>({
    setUpListener: noop,
    removeListener: noop,
});

export const IFramePopupMessageProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const [, setListenerCount] = useState(0);

    const setUpListener = () => {
        setListenerCount((prev) => {
            if (prev === 0) {
                // only set up the listener once
                window.addEventListener('message', listener);
            }
            return prev + 1;
        });
    };

    const removeListener = () => {
        setListenerCount((prev) => {
            if (prev === 1) {
                // only remove the listener if there are no more components that require it
                window.removeEventListener('message', listener);
            }
            return prev - 1;
        });
    };

    return (
        <IFramePopupMessageContext.Provider value={{ removeListener, setUpListener }}>
            {props.children}
        </IFramePopupMessageContext.Provider>
    );
};
