import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import Tooltip from '../../shared/Tooltip';
import FormattedMessage from '../../../localization/FormatMessage';
import colors from '../../../styles/colors.json';
import { FormatIDs } from '../../../types';

const NameColumnTooltip: React.FunctionComponent<{ title: FormatIDs }> = ({ title }) => {
    return (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
            <Tooltip title={<FormattedMessage id={title} />}>
                <ExclamationCircleOutlined style={{ color: colors.yellow700 }} />
            </Tooltip>
        </div>
    );
};

export default NameColumnTooltip;
