import { get } from 'lodash';
import { MeasurementFullData } from '../../../../../types/measurement';
import { renderFolderName } from '../../utils/renderFolderName';
import { FormatMessageFunc } from '../../../../../types';
import { ProductModel, ScanType } from '../../../../../types/proceq';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { getMeasurementMode } from '../../../../../utils/getMeasurementMode';
import { getGSRepetitionRate } from '../../utils/getGSRepetitionRate';

export const getGPRSoilMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    if (!data) {
        return [];
    }

    const creationLog = data.logs?.find((l) => l.type === 'creation');
    const quality = get(data, 'settings.0.content.settings.preset.quality');

    const isLineScan = get(data, 'measurement.type') === 'Line Scan';
    const isAreaScan = get(data, 'measurement.type') === 'Area Scan';
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'File Name',
            value: get(data, 'measurement.name'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.StatisticsFolder',
            value: renderFolderName(data, formatMessage),
            enable: !!isFullList,
        },
        {
            label: 'Mode',
            value: getMeasurementMode(data?.measurement?.type),
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.ScanDirection',
            value: get(data, 'settings.0.content.settings.preset.scanModeParameters.lineScanDirection'),
            enable: isLineScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSizeX',
            unitId: 'GPR_SOIL.CSV.Grid Size - X',
            value: get(creationLog, 'content.measurementInfo.grid.gridSizeWidth'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSizeY',
            unitId: 'GPR_SOIL.CSV.Grid Size - Y',
            value: get(creationLog, 'content.measurementInfo.grid.gridSizeHeight'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSpacingX',
            unitId: 'GPR_SOIL.CSV.Grid Spacing',
            value:
                get(creationLog, 'content.measurementInfo.grid._gridSpacingX') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacingX') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSpacingY',
            unitId: 'GPR_SOIL.CSV.Grid Spacing',
            value:
                get(creationLog, 'content.measurementInfo.grid._gridSpacingY') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacingY') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'Units',
            value: get(data, 'settings.0.content.settings.display.unit'),
        },
        {
            label: 'Resolution',
            value: quality,
            enable: productModel === 'GPR_SOIL',
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.RepetitionRate',
            unitId: 'GPR.CSV.Repetition Rate',
            value: getGSRepetitionRate(get(creationLog, 'content.measurementInfo.repetitionRate')) ?? '',
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};
